<div class="container limited mb-7">
  <div class="mb-4 mb-lg-5">
    <h1 class="h2 mb-1" translate="termsOfUse.title"></h1>
    <p translate="termsOfUse.subTitle"></p>
  </div>
  <app-ikp-terms-content-card></app-ikp-terms-content-card>
  @if (this.userService.userStatusInfo()?.status && this.userService.userStatusInfo()?.status === UserStatus.INVITED) {
    <mwe-info-text-card class="d-block mt-5" text="termsOfUse.info"></mwe-info-text-card>
    <div class="process-footer mt-5 mt-lg-6">
      <div role="navigation" class="buttons py-0">
        <mwe-button
          [buttonId]="'mwe-button-primary'"
          [labelKey]="'termsOfUse.save'"
          class="btn-container d-block back text-uppercase-btn"
          (click)="onAccept()"
          [disabled]="false"
          [showSpinner]="false"
        ></mwe-button>
        <mwe-button
          [buttonId]="'mwe-button-secondary'"
          [labelKey]="'termsOfUse.decline'"
          (click)="onDecline()"
          class="btn-container d-block back text-uppercase-btn"
          [disabled]="false"
          [showSpinner]="false"
          [color]="'orange'"
          [category]="'secondary'"
        ></mwe-button>
      </div>
    </div>
  }
</div>
