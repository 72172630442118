import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '@mwe/ui/components';

@Component({
  selector: 'app-ikp-terms-content-card',
  standalone: true,
  imports: [CommonModule, UiComponentsModule],
  templateUrl: './terms-content-card.component.html',
  styleUrl: './terms-content-card.component.scss',
})
export class TermsContentCardComponent {
  // [1, 2, 3, ..., 12]
  items = Array.from({ length: 12 }, (_, i) => i + 1);

  getScrollTarget(i: number): string {
    return `#${this.getItemId(i)}`;
  }

  getItemId(i: number): string {
    return `item${i}`;
  }

  getItemTitleKey(i: number): string {
    return `termsOfUse.item${i}.title`;
  }
  getItemTextKey(i: number): string {
    return `termsOfUse.item${i}.text`;
  }
}
